<template lang="pug">
.company-contacts
  h4.company-contacts__title {{ $t('layout.footer.help') }}
  hours-available-component.company-contacts__hours(:options="options")
  .company-contacts__email.company-contacts__line.is-flex
    icon.margin-right-10(iconName="email", :iconColor="$site.defaultColor")
    v-link(:to="{name: 'contact-us'}", class="capitalize-text") {{ $t('layout.footer.emailField') }}
  .company-contacts__phone.company-contacts__line.is-flex
    icon(icon-name="flagUSA")
    span.margin-left-10 {{ $t('phones.us') }}
  .company-contacts__address.company-contacts__line.is-flex(v-if="showAddress")
    icon(icon-name="locations", :iconColor="$site.defaultColor")
    .margin-left-10.company-contacts__address--text {{ $t('layout.header.contacts.locations.text1') }}
</template>

<script>
import { mapState } from 'vuex'
import { companyName } from '~~/utils/definitions/defaults'

export default {
  name: 'FooterContactsComponent',

  components: {
    hoursAvailableComponent: () =>
      import(
        '~~/components/Layout/TopHeader/TopHeaderContactsLine/hoursAvailable'
      )
  },

  props: {
    showAddress: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      workingHours: (state) => state.app.workingHours
    }),

    options() {
      return this.workingHours.phone
    },

    companyName() {
      return companyName[this.$site.name]
    }
  }
}
</script>

<style lang="sass" scoped>
.company-contacts
  &__title
    font-size: 20px
    font-style: italic
    font-family: $lora
  &__hours
    margin-top: 10px
  &__line
    margin-top: 10px
  &__phone
    align-items: center
  &__email
    font-size: 16px
    align-items: center
    @include animated-link()
  &__address
    font-size: 16px
  &__address--text
    max-width: 208px
  ::v-deep
    .hours-available-title
      display: none
    .hours-available-text
      font-size: 16px
  & .capitalize-text
    text-transform: capitalize
</style>
